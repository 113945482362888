import React from 'react'
import P from '../../core/P';

export default function TechnologyTools({TechnologyToolsBlock:{feature,gridSize:size},theme }) {
    const [current, setCurrent] = React.useState()
    const SectionA = e => (
      <>
        {e.icon && (
          <div className={`${e.title ? "pb-5" : " "} h-24`}>
            <div className="flex justify-center align-middle">
              <div className={`${current === "/gitex-technology-week/" ? "w-24" : "w-16"
                } h-auto rounded-md text-white flex justify-center`}>
                <div className="w-full h-auto">
                  <img
                    src={e.icon?.mediaItemUrl}
                    loading="lazy"
                    height={e.icon?.mediaDetails?.height}
                    width={e.icon?.mediaDetails?.width}
                    alt={e.icon?.altText ? e.icon?.altText : e.title}
                    // title={e.title}
                  // className={`${title ? "pb-5" : " "}`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {e.title && (
          <P
            title={e.title}
            className={`font-bold text-center text-shark-400 ${e.description ? "pb-5" : " "
              }`}
            noPad
          />
        )}
        {e.description && (
          <P
            title={e.description}
            className={`text-center text-shark-400`}
            noPad
          />
        )}
      </>
    )
    const SectionB = e => (
      <>
        {e.icon && (
          <div className={`${e.title ? "pb-5" : " "} ${current === "/gitex-2022/" ? "h-32" : "h-24"
            }`}>
            <div className="flex overflow-hidden justify-center align-middle">
              <div className={`${current === "/gitex-2022/" ? "w-28 " : "w-16"
                } h-auto rounded-md text-white flex justify-center`}>
                <div className="w-full h-auto ">
                  <img
                    src={e.icon?.mediaItemUrl}
                    loading="lazy"
                    height={e.icon?.mediaDetails?.height}
                    width={e.icon?.mediaDetails?.width}
                    alt={e.icon?.altText ? e.icon?.altText : e.title}
                    // title={e.title}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {e.title && (
          <div className={`flex mx-auto justify-center items-center ${e.description ? "pb-5" : " "}`} >
            <p
              className={`font-bold text-center text-lg font-sans max-w-4xl`}
              dangerouslySetInnerHTML={{ __html: e.title }}
            />
            {
              e.sicon?.mediaItemUrl &&
              <div className="w-5 h-5 ml-2 z-30 ">
                <a
                  href={e?.siconlink?.url ? e.siconlink.url : "#"}
                  className={`cursor-pointer`}
                  target={e?.siconlink?.target}
                  onClick={(e)=> e.stopPropagation()}
                >
                  <img
                    src={e.sicon?.mediaItemUrl}
                    loading="lazy"
                    height={e.sicon?.mediaDetails?.height}
                    width={e.sicon?.mediaDetails?.width}
                    alt={e.sicon?.altText ? e.sicon?.altText : e.title}
                    // title={e.sicon?.altText ? e.sicon?.altText : e.title}
                  // className={`${title ? "pb-5" : " "}`}
                  />
                </a>
              </div>
            }
          </div>
        )}
        {e.description && (
          <P title={e.description} className={`text-center`} noPad />
        )}
      </>
    )
  return (
    <>
       <div className="flex justify-center bg-blue-200 mx-auto">
        <div className="px-4 sm:px-6 w-screen lg:px-12  ">
          <div
            className={`lg:grid lg:grid-cols-${size} lg:gap-6 md:grid md:grid-cols-2 md:gap-6 `}
            data-aos="fade-up"
          >
            {feature?.map(({TechnologyToolsCPT:e}) => {
              const pointer = e?.cta?.url ? "cursor-pointer" : "cursor-default"
              return theme ? (
                <div
                  className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e?.icon
                    ? "transition border-t-4 border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out"
                    } `}
                  key={Math.random()}
                >
                  {e?.cta?.url ? (
                    <a
                      href={e?.cta?.url ? e.cta.url : "#"}
                      className={`${pointer}`}
                      target={`${current === "/gitex-technology-week/" ? "_blank" : ""
                        }`}
                    >
                      {SectionA(e)}
                    </a>
                  ) : (
                    <>{SectionA(e)}</>
                  )}
                </div>
              ) : (
                <div
                  className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                    ? "transition border-t-4 hover:border-blue-500 border-white hover:shadow-xl hover:-translate-y-1 text-shark-400"
                    : "hover:bg-blue-500 hover:shadow-xl text-shark-400 hover:text-white transition duration-200 ease-in-out icon-card"
                    } `}
                  key={Math.random()}
                >
                  {e.cta?.url ? (
                    e.cta?.url.charAt(0) === "#" ?
                      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                      <div className={`${pointer}`}>
                        {SectionB(e)}
                      </div> :
                      <a
                        href={e?.cta?.url ? e.cta.url : "#"}
                        className={`${pointer}`}
                        target={`${current === "/gitex-technology-week/" ? "_blank" : ""
                          }`}
                      >
                        {SectionB(e)}
                      </a>
                  ) : (
                    <>{SectionB(e)}</>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
